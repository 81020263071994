import React from "react"
import cmptStyles from "../../styles/shared/link.module.css"
import { Link } from "gatsby"
import { Location } from '@reach/router'
import { OutboundLink } from "gatsby-plugin-google-gtag"

export const AutoLink = (
    {
        uri,
        children
    }
) => {

    const isExternal = ( (uri.indexOf('http') > -1) || (uri.indexOf('mailto') > -1) );

    if ( isExternal ) {

        const friendlyExternals = [
            'xptlife.com',
            'shop.xptlife',
            'xptlife.docebosaas'
        ];
    
        let isFriendly = false;
    
        friendlyExternals.forEach( item => {
            if ( uri.indexOf(item) > -1  ) {
                isFriendly = true;
            }
        });

        if( isFriendly ) {
            return (
                <OutboundLink 
                    className={ cmptStyles.internalLink } 
                    href={ uri }
                    >
                    { children }
                </OutboundLink>
            )
        } else {
            return (
                <OutboundLink 
                    className={ cmptStyles.externalLink } 
                    href={ uri }
                    rel="noopener noreferrer"
                    target="_blank"
                    >
                    { children }
                </OutboundLink>
            )
        }

    } else {

        return (
            <Location>
                {({ location }) => {

                    let modifiedUri = "/" + uri;
                    let isAmp = ( location.pathname.indexOf('amp/') !== -1 );

                    if ( isAmp ) {
                        modifiedUri = 'amp/' + modifiedUri;
                    }

                    return (
                        <Link to={ modifiedUri } activeClassName="active" className={ cmptStyles.internalLink }>
                            { children }
                        </Link>
                    )
                }}
            </Location>
        )

    }

}