import React, { useState } from "react"
import cmptStyles from "../styles/header.module.css"
import { graphql } from "gatsby"
import Icon from "./shared/icon"
import { AutoLink } from "./shared/link"

const Header = ({ 
    content: { 
        header: {
            homeLink,
            navigationLinks, 
            socialLinks
        } 
    }, 
    siteFurniture: { 
        logoWithText
    }
}) => {

    const [ isMenuOpen, setIsMenuOpen ] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const cmptMenuStatus = ( isMenuOpen === true ) ? cmptStyles.menuOpen : cmptStyles.menuClosed;

    return (
        <div className={ cmptStyles.wrapper } id="header">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                    
                    <div className={ cmptStyles.logo }>
                        <span title={ homeLink.label }>
                            <AutoLink uri={ homeLink.route }>
                                <img
                                    src={ logoWithText.file }
                                    alt={ logoWithText.altText }
                                />
                            </AutoLink>
                        </span>
                    </div>
                    <div className={ cmptStyles.menu }>
                        <button onClick={toggleMenu}>
                            <span className={ cmptStyles.menuIcon }>
                                <Icon theme="dark" name="menu" />
                            </span>
                        </button>
                    </div>

                    <nav className={ [ cmptStyles.navigationMenu, cmptMenuStatus ].join(' ') }>
                        <div className={ cmptStyles.close }>
                            <button onClick={toggleMenu}>
                                <span className={ cmptStyles.closeIcon }>
                                    <Icon theme="dark" name="close" />
                                </span>
                            </button>
                        </div>
                        <div className={ cmptStyles.navigationLinks }>
                            <ul>
                                { navigationLinks.map( link => {
                                    return (
                                        <li className={ cmptStyles.navigationLink }>
                                            <AutoLink uri={ link.route }>
                                                { link.label }
                                            </AutoLink>
                                        </li>
                                    )
                                }) }
                            </ul>
                        </div>
                        <div className={ cmptStyles.socialLinks }>
                            <ul>
                                { socialLinks.map( link => {
                                    return (
                                        <li className={ cmptStyles.socialLink }>
                                            <AutoLink uri={ link.route }>
                                            {
                                                link.icon &&
                                                <span title={ link.label } className={ cmptStyles.socialIcon }>
                                                    <Icon theme="dark" name={ link.icon } />
                                                </span>
                                            }
                                            </AutoLink>
                                        </li>
                                    )
                                }) }
                            </ul>
                        </div>
                    </nav>

                </div>
            </div>
        </div>
    )
}

export default Header

export const headerQuery = graphql`
    fragment Header on DataJson {
        header {
            internal {
                type
            }
            homeLink {
                label
                route
            }
            navigationLinks {
                label
                route
            }
            socialLinks {
                icon
                label
                route
            }
        }
    }
`