import React from "react"
import cmptStyles from "../../styles/shared/icon.module.css"

const Icon = ({ 
    name, 
    theme 
}) => {

    if (theme === 'light') {
        switch(name.toLowerCase()) {
            case 'breathe':
                return (<span className={ cmptStyles.lightBreathe } key={ name }/>);
            case 'close':
                return (<span className={ cmptStyles.lightClose } key={ name }/>);
            case 'coach':
                return (<span className={ cmptStyles.lightCoach } key={ name }/>);
            case 'facebook':
                return (<span className={ cmptStyles.lightFacebook } key={ name }/>);
            case 'instagram':
                return (<span className={ cmptStyles.lightInstagram } key={ name }/>);
            case 'menu':
                return (<span className={ cmptStyles.lightMenu } key={ name }/>);
            case 'move':
                return (<span className={ cmptStyles.lightMove } key={ name }/>);
            case 'profile':
                return (<span className={ cmptStyles.lightProfile } key={ name }/>);
            case 'recover':
                return (<span className={ cmptStyles.lightRecover } key={ name }/>);
            case 'search':
                return (<span className={ cmptStyles.lightSearch } key={ name }/>);
            case 'shop':
                return (<span className={ cmptStyles.lightShop } key={ name }/>);
            case 'tribe':
                return (<span className={ cmptStyles.lightTribe } key={ name }/>);
            case 'twitter':
                return (<span className={ cmptStyles.lightTwitter } key={ name }/>);
            case 'youtube':
                return (<span className={ cmptStyles.lightYoutube } key={ name }/>);
    
            default:
                return (<span>Icon not recognized: { name }</span>)
        }
    }

    if (theme === 'dark') {
        switch(name.toLowerCase()) {
            case 'breathe':
                return (<span className={ cmptStyles.darkBreathe } key={ name }/>);
            case 'close':
                return (<span className={ cmptStyles.darkClose } key={ name }/>);
            case 'coach':
                return (<span className={ cmptStyles.darkCoach } key={ name }/>);
            case 'facebook':
                return (<span className={ cmptStyles.darkFacebook } key={ name }/>);
            case 'instagram':
                return (<span className={ cmptStyles.darkInstagram } key={ name }/>);
            case 'menu':
                return (<span className={ cmptStyles.darkMenu } key={ name }/>);
            case 'move':
                return (<span className={ cmptStyles.darkMove } key={ name }/>);
            case 'profile':
                return (<span className={ cmptStyles.darkProfile } key={ name }/>);
            case 'recover':
                return (<span className={ cmptStyles.darkRecover } key={ name }/>);
            case 'search':
                return (<span className={ cmptStyles.darkSearch } key={ name }/>);
            case 'shop':
                return (<span className={ cmptStyles.darkShop } key={ name }/>);
            case 'tribe':
                return (<span className={ cmptStyles.darkTribe } key={ name }/>);
            case 'twitter':
                return (<span className={ cmptStyles.darkTwitter } key={ name }/>);
            case 'youtube':
                return (<span className={ cmptStyles.darkYoutube } key={ name }/>);
    
            default:
                return (<span>Icon not recognized: { name }</span>)
        }
    }

}

export default Icon