import React from "react"
import cmptStyles from "../styles/footer.module.css"
import { graphql } from "gatsby"
import Icon from "./shared/icon"
import { AutoLink } from "./shared/link"

const Collection = ({ children, links }) => (
    <ul className={ cmptStyles.groupLinks }>
        <li className={ cmptStyles.collectionTitle }>
            { children }
        </li>
        { links.map( link => {
            return (
                <li className={ cmptStyles.collectionLink }>
                    <AutoLink uri={ link.route }>
                        { link.label }
                    </AutoLink>
                </li>
            )
        }) }
    </ul>
)

const SocialCollection = ({ children, links }) => (
    <ul className={ cmptStyles.socialLinks }>
        <li className={ cmptStyles.collectionTitle }>
            { children }
        </li>
        { links.map( link => {
            return (
                <li className={ cmptStyles.socialLink }>
                    <AutoLink uri={ link.route }>
                    {
                        link.icon &&
                        <span title={ link.label } className={ cmptStyles.socialIcon }>
                            <Icon theme="light" name={ link.icon } />
                        </span>
                    }
                    </AutoLink>
                </li>
            )
        }) }
    </ul>
)

const Footer = ({ 
    content: { 
        footer: {
            socialTitle,
            socialLinks, 
            group1Title, 
            group1Links, 
            group2Title, 
            group2Links, 
            copyrightLine1
        }
    } 
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="footer">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>

                    <div className={ cmptStyles.links }>
                        {
                            socialLinks &&
                            <SocialCollection links={ socialLinks }>
                                { socialTitle }
                            </SocialCollection>
                        }
                        {
                            group1Links &&
                            <Collection links={ group1Links }>
                                { group1Title }
                            </Collection>
                        }
                        {
                            group2Links &&
                            <Collection links={ group2Links }>
                                { group2Title }
                            </Collection>
                        }
                    </div>
                    <footer>
                        <p className={ cmptStyles.copyright }>{ copyrightLine1.replace('{{{TOKEN_YEAR}}}', new Date().getFullYear()) }</p>
                    </footer>

                </div>
            </div>
        </section>
    )
}

export default Footer

export const footerQuery = graphql`
    fragment Footer on DataJson {
        footer {
            internal {
                type
            }
            socialTitle
            socialLinks {
                icon
                label
                route
            }
            group1Title
            group1Links {
                label
                route
            }
            group2Title
            group2Links {
                label
                route
            }
            copyrightLine1
        }
    }
`