import React, { useState, useEffect } from "react"
import cmptStyles from "../../styles/shared/support.module.css"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet";

const Support = () => {
    const data = useStaticQuery(
        graphql`
            query {
                dataJson {
                    ...Support
                }
            }
        `
    )

    const support = data.dataJson.support;

    const [ isScriptLoaded, setIsScriptLoaded ] = useState(null);
    const [ shouldLoadScript, setShouldLoadScript ] = useState(null);

    const loadScript = () => {
        setShouldLoadScript(true);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if ( document.getElementById("ze-snippet") || ( document.getElementById("launcher") ) !== null ) {
                setIsScriptLoaded(true);
            } else {
                setIsScriptLoaded(false);
            }
        }, 2000);
        return () => clearTimeout(timer);
    }, [isScriptLoaded, shouldLoadScript]);

    return (
        <>
        {
            shouldLoadScript &&
            <Helmet>
                { /* Start of xptlife Zendesk Widget placeholder script */ }
                <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=694f39ed-26fd-458d-8efb-0ea5a52ad9d8"></script>
                { /* End of xptlife Zendesk Widget script */ }
            </Helmet>
        }
        {
            ( isScriptLoaded === false ) &&
            <section className={ cmptStyles.wrapper } id="support">
                <div className={ cmptStyles.container }>
                    <div className={ cmptStyles.inner }>

                    <button 
                        aria-haspopup="false" 
                        className={ cmptStyles.loadScriptButton }
                        onMouseEnter={ () => loadScript() }
                        onFocus={ () => loadScript() }
                    >
                        <span 
                            className={ cmptStyles.loadScriptButtonIcon }
                            type="Icon"
                            dangerouslySetInnerHTML={{ __html: support.icon }}
                        />
                        <span 
                            className={ cmptStyles.loadScriptButtonLabel }
                        >
                            { support.label }
                        </span>
                    </button>

                    </div>
                </div>
            </section>
        }
        </>
    )
}

export default Support

export const supportQuery = graphql`
    fragment Support on DataJson {
        support {
            label
            icon
        }
    }
`